import React from 'react'
import Button from '../ui-elements/button'
import { ThemeContext } from '../../../styles/theme-provider'

import { Wrap, Logo, Title, Right, Tip, SiteTitle } from './index.styled'

const Header = ({ className, title = '', ...props }) => {
  const { setResetForm } = React.useContext(ThemeContext)

  return (
    <>
      <Wrap className={[className].join(' ')} {...props} id="header">
        <SiteTitle className={'mob-only'}>rentals</SiteTitle>

        <Logo to="/" />
        <Tip>Return to master plan</Tip>

        <Title to={'/rentals/'}>Rental Collection</Title>
        <Right>
          <Button
            to="/rentals/availability/"
            title="availability"
            className="header"
            onClick={() => setResetForm(true)}
          />
          <Button
            to="/rentals/contact/"
            title="schedule a tour"
            className="header web-only"
            onClick={() => setResetForm(true)}
          />
          <Button
            to="/rentals/contact/"
            title="contact"
            className="header mob-only"
            onClick={() => setResetForm(true)}
          />
        </Right>
      </Wrap>
    </>
  )
}
export default Header
