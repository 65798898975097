import React from 'react'
import Modal from 'components/ui-elements/modal'
import mapimg from 'images/rentals/map.png'
import { MapButton, BackButton, CloseButton, Content } from './index.styled'

const Map = ({ location }) => {
  const [open, setOpen] = React.useState(false)
  const isRentalLocationPage = location.pathname === '/rentals/neighborhood/'

  return (
    <>
      <MapButton
        onClick={() => setOpen(true)}
        className={!isRentalLocationPage && 'hide'}
      />
      <Modal open={open}>
        <Content>
          <BackButton onClick={() => setOpen(false)} type="button" />
          <CloseButton onClick={() => setOpen(false)} type="button" />
          <img src={mapimg} alt={''} />
        </Content>
      </Modal>
    </>
  )
}
export default Map
