import React from 'react'
import styled from 'styled-components'
import {
  calculateResponsiveSize,
  calculateMobileResponsiveSize,
  mediaMax,
} from 'styles/media-queries'
import { Link } from 'gatsby'

export const Element = styled((props) => <Link {...props} />)`
  font-family: 'Berthold Akzidenz Grotesk';

  font-size: ${calculateResponsiveSize(20)};
  font-weight: 500;
  line-height: ${calculateResponsiveSize(26)};
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  text-decoration: none;

  border: 1px solid var(--blue);
  padding: ${calculateResponsiveSize(6)} ${calculateResponsiveSize(26)};

  transition: background-color 0.5s, color 0.5s;

  background-color: transparent;
  color: var(--blue);

  &:visited {
    color: var(--blue);
  }
  &:hover {
    background-color: var(--blue);
    color: var(--white);
    &:visited {
      color: var(--white);
    }
  }
  &.header {
    background-color: var(--white);
    color: var(--blue);
    border: 1px solid var(--white);

    padding: ${calculateResponsiveSize(8)} ${calculateResponsiveSize(8)}
      ${calculateResponsiveSize(2)} ${calculateResponsiveSize(8)};

    &:hover {
      border: 1px solid var(--white);
      color: var(--white);
      background-color: var(--blue);
    }
  }
  ${mediaMax('mobile')} {
    height: ${calculateMobileResponsiveSize(32)};

    padding-top: ${calculateMobileResponsiveSize(2)};
    padding-left: ${calculateMobileResponsiveSize(13)};
    padding-right: ${calculateMobileResponsiveSize(13)};

    font-size: ${calculateMobileResponsiveSize(14)};
    line-height: ${calculateMobileResponsiveSize(15.4)};
    &.small {
      height: ${calculateMobileResponsiveSize(19)};
      font-size: ${calculateMobileResponsiveSize(12)};
      line-height: ${calculateMobileResponsiveSize(16)};
      padding-left: ${calculateMobileResponsiveSize(7)};
      padding-right: ${calculateMobileResponsiveSize(7)};
    }

    border: 1px solid var(--blue);
    &:hover {
      background-color: transparent;
      color: var(--blue);
      &:visited {
        color: var(--blue);
      }
    }

    &.header {
      &:hover {
        background-color: var(--white);
        color: var(--blue);
        border: 1px solid var(--white);
      }
      font-size: ${calculateMobileResponsiveSize(12)};
      line-height: ${calculateMobileResponsiveSize(14)};
      letter-spacing: 0em;
      height: ${calculateMobileResponsiveSize(20)};

      padding: ${calculateMobileResponsiveSize(5)}
        ${calculateMobileResponsiveSize(6)} ${calculateMobileResponsiveSize(3)}
        ${calculateMobileResponsiveSize(6)};
    }
  }
`
