import React from 'react'
import styled from 'styled-components'
import {
  calculateResponsiveSize,
  calculateMobileResponsiveSize,
  mediaMax,
} from 'styles/media-queries'
import { Link } from 'gatsby'
import HeaderLogo from 'icons/logo.svg'

export const Logo = styled((props) => <Link {...props} />)`
  width: ${calculateResponsiveSize(120)};
  height: ${calculateResponsiveSize(90)};
  -webkit-mask: url(${HeaderLogo}) no-repeat;
  -webkit-mask-size: contain;
  background: #fff;
  display: flex;
  &:hover + div {
    opacity: 1;
  }

  ${mediaMax('mobile')} {
    width: ${calculateMobileResponsiveSize(50)};
    height: ${calculateMobileResponsiveSize(40)};
    /*  margin-left: auto; */
    flex: 0 0 auto;
  }
`
export const Title = styled((props) => <Link {...props} />)`
  color: #fff;
  font-family: 'Berthold Akzidenz Grotesk';
  font-size: ${calculateResponsiveSize(36)};
  font-weight: 500;
  line-height: ${calculateResponsiveSize(46.8)};
  letter-spacing: 0.03em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(${calculateResponsiveSize(5)});
  &:active,
  &:visited,
  &:hover {
    color: #fff;
  }

  ${mediaMax('mobile')} {
    display: none;
  }
`
export const SiteTitle = styled((props) => <Link {...props} />)`
  font-family: 'Berthold Akzidenz Grotesk';
  font-weight: 500;
  font-size: ${calculateMobileResponsiveSize(14)};
  leading-trim: Cap height;
  line-height: ${calculateMobileResponsiveSize(18.2)};
  letter-spacing: 0.07em;
  color: #fff;
  text-transform: uppercase;
`

export const Wrap = styled.header`
  display: flex;
  align-items: center;
  padding-right: ${calculateResponsiveSize(42)};
  justify-content: space-between;
  background: var(--blue);
  border-bottom: 1px solid var(--Warm-Gray-Tint-2);
  &.beige-reverse {
    background: var(--Warm-Gray-Tint-2);
  }
  ${mediaMax('mobile')} {
    height: ${calculateMobileResponsiveSize(60)};
    padding: 0 ${calculateMobileResponsiveSize(32)};
  }
`

export const Right = styled.div`
  display: flex;
  margin-right: ${calculateResponsiveSize(78)};
  gap: ${calculateResponsiveSize(24)};
  ${mediaMax('mobile')} {
    margin-right: ${calculateMobileResponsiveSize(50)};
    gap: ${calculateMobileResponsiveSize(12)};
    margin-left: auto;
  }
`
export const Tip = styled.div`
  opacity: 0;
  transition: 0.5s;
  font-family: 'GT America Condensed';
  font-size: ${calculateResponsiveSize(18)};
  font-weight: 300;
  line-height: ${calculateResponsiveSize(24)};
  text-align: center;
  color: #fff;
  display: flex;
  align-items: center;
  position: absolute;
  left: ${calculateResponsiveSize(120)};
  text-transform: uppercase;
`
