import React from 'react'
import { useLocation } from '@reach/router'
import { graphql, useStaticQuery } from 'gatsby'

import { Container, Next } from './index.styled'

const NavigationNext = ({ layout = '' }) => {
  const {
    rentalsMenuContent: {
      content: { primary },
    },
  } = useStaticQuery(graphql`
    query MenuRentalsQuery {
      rentalsMenuContent {
        content {
          primary {
            text
            url
          }
        }
      }
    }
  `)

  const location = useLocation()

  const index = primary.findIndex(
    ({ url }) =>
      location.pathname === url ||
      (url !== '/rentals/' && location.pathname.startsWith(url)),
  )

  const next =
    index === -1 || index + 1 >= primary.length
      ? primary[0]
      : primary[index + 1]

  if (
    location.pathname === '/rentals/contact/' ||
    location.pathname === '/rentals/team/' ||
    location.pathname === '/rentals/legal/'
  )
    return null

  return (
    <Container className={[layout].join(' ')}>
      <Next to={next.url}>{next.url === '/rentals/' ? 'home' : next.text}</Next>
    </Container>
  )
}

export default NavigationNext
