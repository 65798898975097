import styled from 'styled-components'
import {
  calculateResponsiveSize,
  calculateMobileResponsiveSize,
  mediaMax,
} from 'styles/media-queries'

export const Sections = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${calculateResponsiveSize(120)};

  ${mediaMax('mobile')} {
    gap: ${calculateMobileResponsiveSize(60)};
    section:last-child {
      margin-bottom: ${calculateMobileResponsiveSize(0)};
    }
  }
`

export const HeaderPlaceholder = styled.div`
  height: ${calculateResponsiveSize(90)};
  background: white;
  width: 100%;
  z-index: 10;
  position: relative;

  ${mediaMax('mobile')} {
    height: ${calculateMobileResponsiveSize(60)};
    order: -2;
  }
`
