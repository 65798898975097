import styled from 'styled-components'
import {
  calculateResponsiveSize,
  calculateMobileResponsiveSize,
  mediaMax,
} from 'styles/media-queries'

import MapIcon from 'icons/map.svg'
import CloseIcon from 'icons/close.svg'
import CloseMobIcon from 'icons/close-mob.svg'

import BackIcon from 'icons/back.svg'
import BackMobIcon from 'icons/arrow-back-mob.svg'

export const MapButton = styled.div`
  width: ${calculateResponsiveSize(52)};
  height: ${calculateResponsiveSize(52)};
  background: var(--blue) url(${MapIcon}) no-repeat center;
  background-size: ${calculateResponsiveSize(22)} ${calculateResponsiveSize(28)};
  display: flex;
  position: fixed;
  z-index: 10;
  bottom: ${calculateResponsiveSize(26)};
  right: ${calculateResponsiveSize(33)};
  box-shadow: 0px 0px 12px 0px #ffffff40;
  border-radius: 50%;
  cursor: pointer;
  &.hide {
    display: none;
  }
  ${mediaMax('mobile')} {
    width: ${calculateMobileResponsiveSize(32)};
    height: ${calculateMobileResponsiveSize(32)};
    bottom: ${calculateMobileResponsiveSize(26)};
    right: ${calculateMobileResponsiveSize(33)};
    background-size: ${calculateMobileResponsiveSize(13.5)}
      ${calculateMobileResponsiveSize(16)};
  }
`
export const CloseButton = styled.button`
  width: ${calculateResponsiveSize(26)};
  height: ${calculateResponsiveSize(26)};
  -webkit-mask: url(${CloseIcon}) no-repeat;
  -webkit-mask-size: contain;
  background: var(--blue);
  display: flex;
  top: ${calculateResponsiveSize(33)};
  right: ${calculateResponsiveSize(45)};
  position: absolute;
  cursor: pointer;
  ${mediaMax('mobile')} {
    -webkit-mask: url(${CloseMobIcon}) no-repeat;
    -webkit-mask-size: contain;
    width: ${calculateMobileResponsiveSize(17)};
    height: ${calculateMobileResponsiveSize(17)};
    top: ${calculateMobileResponsiveSize(21)};
    right: ${calculateMobileResponsiveSize(35)};
  }
`
export const BackButton = styled.button`
  cursor: pointer;
  width: ${calculateResponsiveSize(44)};
  height: ${calculateResponsiveSize(44)};
  -webkit-mask: url(${BackIcon}) no-repeat;
  -webkit-mask-size: contain;
  background: var(--blue);
  display: flex;
  top: ${calculateResponsiveSize(26)};
  left: ${calculateResponsiveSize(29)};
  position: absolute;
  ${mediaMax('mobile')} {
    -webkit-mask: url(${BackMobIcon}) no-repeat;
    -webkit-mask-size: contain;
    width: ${calculateMobileResponsiveSize(22)};
    height: ${calculateMobileResponsiveSize(17)};
    top: ${calculateMobileResponsiveSize(21)};
    left: ${calculateMobileResponsiveSize(32)};
  }
`

export const Content = styled.div`
  background: #fff;
  img {
    height: 100dvh;
  }
  ${mediaMax('mobile')} {
    height: 100dvh;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: auto;
      width: 100vw;
    }
  }
`
