import styled from 'styled-components'
import {
  calculateResponsiveSize,
  calculateMobileResponsiveSize,
  mediaMax,
} from 'styles/media-queries'

export const Sections = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${calculateResponsiveSize(120)};
  section:last-child {
    margin-bottom: ${calculateResponsiveSize(220)};
  }
  .beige & {
    gap: ${calculateResponsiveSize(0)};
    section:last-child {
      margin-bottom: ${calculateResponsiveSize(0)};
    }
  }
  .beige-reverse .oww-neighborhood & {
    gap: ${calculateResponsiveSize(120)};
    section:last-child {
      margin-bottom: ${calculateResponsiveSize(0)};
    }
  }
  .rentals-neighborhood & {
    section:last-child {
      margin-bottom: ${calculateResponsiveSize(0)};
    }
  }
  ${mediaMax('mobile')} {
    .beige-reverse .oww-neighborhood & {
      gap: ${calculateMobileResponsiveSize(60)};
    }
    gap: ${calculateMobileResponsiveSize(60)};
    section:last-child {
      margin-bottom: ${calculateMobileResponsiveSize(120)};
    }
  }
`

export const HeaderPlaceholder = styled.div`
  height: ${calculateResponsiveSize(90)};
  background: white;
  width: 100%;
  z-index: 10;
  position: relative;

  ${mediaMax('mobile')} {
    height: ${calculateMobileResponsiveSize(60)};
    order: -2;
  }
`

export const Copy = styled.div`
  font-size: ${calculateResponsiveSize(37)};
  text-align: center;
  leading-trim: both;

  text-edge: cap;
  font-family: 'Berthold Akzidenz Grotesk';
  font-style: normal;
  font-weight: 300;
  line-height: 140%;

  padding: ${calculateResponsiveSize(130)} ${calculateResponsiveSize(260)};

  ${mediaMax('mobile')} {
  }
`

export const Splash = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  min-width: 320px;
  height: 100dvh;
  z-index: 200;
  background-color: var(--blue);

  transition: opacity 1s;
  overflow: hidden;
  opacity: 1;

  &.hide {
    opacity: 0;
  }
`

export const ArrowContainer = styled.div`
  position: absolute;
  height: ${calculateResponsiveSize(128)};
  bottom: ${calculateResponsiveSize(14)};
  left: 50%;
  transform: translate(-50%, 0%);

  cursor: pointer;
  & #lottie {
    height: ${calculateResponsiveSize(128)};
  }

  ${mediaMax('mobile')} {
    height: ${calculateMobileResponsiveSize(64)};
    bottom: ${calculateMobileResponsiveSize(14)};
    display: none;
    & #lottie {
      height: ${calculateMobileResponsiveSize(64)};
    }
  }
`
